<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
        >
          <el-row :gutter="10">
            <!-- 基本信息 -->
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">事故信息</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="养护企业" prop="careUnitName">
                <el-select v-model="form.careUnitName" placeholder="请选择">
                  <el-option
                    v-for="item in careUnitNameOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="标段名称" prop="bidId">
                <el-cascader
                  v-model="form.bidId"
                  placeholder="请选择"
                  :options="bidList"
                  :show-all-levels="false"
                  filterable
                  @change="hanldBidId"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="事故时间" prop="accidentDate">
                <el-date-picker
                  v-model="form.accidentDate"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="事故类型" prop="accidentType">
                <el-select v-model="form.accidentType" placeholder="请选择" filterable>
                  <el-option
                    v-for="item in accidentTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="相关人员">
                <el-select
                  v-model="form.accidentPersonList"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in accidentPersonListOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="相关车辆">
                <el-select
                  v-model="form.accidentPlateList"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in accidentPlateListOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="事故描述" prop="accidentRemark">
                <el-input v-model="form.accidentRemark" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="位置信息" prop="accidentPosition">
                <el-input v-model="form.accidentPosition" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="相关照片" style="width: 100%">
                <ayl-img-upload
                  v-model="accidentImageUrls"
                  :imgListLength="50"
                  :imgWidth="'130px'"
                  :imgHeight="'130px'"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">处置信息</div>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="处置负责人" prop="handleName">
                <el-input v-model="form.handleName" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="处置人联系方式" prop="handlePhone">
                <el-input v-model="form.handlePhone" placeholder="请输入" maxlength="11"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="责任人" prop="responsibleName">
                <el-input v-model="form.responsibleName" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="责任人联系方式" prop="responsiblePhone">
                <el-input v-model="form.responsiblePhone" placeholder="请输入" maxlength="11"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="处置方式" prop="handleType">
                <el-input v-model="form.handleType" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="处置结果" prop="handleResult">
                <el-input v-model="form.handleResult" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="相关证明文件" style="width: 100%">
                <!-- <ayl-upload-files v-model="form.handleUrls" :accept="filType" :maxSize="50"></ayl-upload-files> -->
                <ayl-upload-files v-model="handleUrls" :limit="3" :maxSize="10240" :accept="''"></ayl-upload-files>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">提交</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      debug: 0,
      nav: [{ name: "处置安全事故记录" }],
      bidList: [],
      careUnitNameOption: [],
      accidentPersonListOption: [],
      accidentPlateListOption: [],
      accidentTypeOptions: [
        {
          value: "0",
          label: "车辆"
        },
        {
          value: "1",
          label: "人员"
        }
      ],
      submitLoading: false,
      form: {
        careUnitName: null, //养护企业
        bidId: null, // 标段名称
        accidentDate: null, // 事故时间
        accidentType: null, // 事故类型
        accidentPersonList: null, //相关人员
        accidentPlateList: null, //相关车辆
        accidentRemark: null, //事故描述
        accidentPosition: null, //位置信息
        accidentImageUrls: null, //相关照片
        handleName: null, //处置负责人
        handlePhone: null, // 处置人联系方式
        responsibleName: null, //责任人
        responsiblePhone: null, // 责任人联系方式
        handleType: null, // 处置方式
        handleResult: null, // 处置结果
        handleUrls: null // 处置相关文件证明
      },
      // 相关照片
      accidentImageUrls: [],
      // 处置相关文件证明
      handleUrls: [],
      // 必填字段校验
      rules: {
        careUnitName: {
          required: true,
          message: "请选择养护企业",
          trigger: "change"
        },
        bidId: {
          required: true,
          message: "请输入车牌号码",
          trigger: "blur"
        },
        accidentDate: {
          required: true,
          message: "请选择事故时间",
          trigger: "change"
        },
        accidentType: {
          required: true,
          message: "事故类型",
          trigger: "change"
        },
        responsibleName: {
          required: true,
          message: "请输入责任人",
          trigger: "blur"
        },
        responsiblePhone: {
          required: true,
          message: "请输入责任人联系电话",
          trigger: "blur"
        },
        handleType: {
          required: true,
          message: "请输入处置方式",
          trigger: "blur"
        },
        handleResult: {
          required: true,
          message: "请输入处置结果",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    async hanldBidId(arr) {
      this.form.bidId = arr[1];
      if (arr[0] !== 0) {
        this.form.accidentPersonList = null;
        this.form.accidentPlateList = null;
      }
      // 获取相关车辆
      await this.$api_hw
        .operationSafetyManagement_getAllCarsUnderBid({
          bidId: arr[1]
        })
        .then(res => {
          this.log(res, "获取相关车辆");
          this.accidentPlateListOption = res.map(item => {
            return {
              value: item,
              label: item
            };
          });
          this.log(this.accidentPlateListOption);
        });
      // 获取相关人员
      await this.$api_hw
        .operationSafetyManagement_getAllPersonUnderBid({
          bidId: arr[1]
        })
        .then(res => {
          this.log(res, "获取相关人员");
          this.accidentPersonListOption = res;
        });
    },
    //关闭
    onClose() {
      BUS.$emit(BUSEVENT.REFRESH_SAFETY_INCIDENT_RECORD);
      this.$emit("onClose"); //
      this.$refs["form"].resetFields();
    },

    async submit() {
      let params = JSON.parse(JSON.stringify(this.form));
      params.accidentPersonList = this.form.accidentPersonList.join(",");
      params.accidentPlateList = this.form.accidentPlateList.join(",");
      params.accidentDate = this.form.accidentDate + "";
      params.accidentImageUrls = this.accidentImageUrls.join(",");
      // 处置相关文件证明
      params.handleUrls = JSON.stringify(this.handleUrls);
      await this.$api_hw
        .operationSafetyManagement_updateAccidentInfo({
          sanitationAccidentHandleVO: params
        })
        .then(res => {
          this.$notify({
            title: "成功",
            message: `${this.nav[0].name}成功`,
            type: "success"
          });
          this.onClose();
        });
      this.log(params);
    },
    // 提交表单
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        this.submitLoading = true;
        try {
          this.submit();
        } catch (e) {
          console.log(e);
        } finally {
          this.submitLoading = false;
        }
      });
    }
  },
  async mounted() {
    //获取所属标段下拉
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});

    // 获取养护
    await this.$api_hw.districtInfo_getDistinctCareUnit({}).then(res => {
      this.careUnitNameOption = res.map(item => {
        return { value: item, label: item };
      });
    });

    //获取详情数据
    let formData = await this.$api_hw.operationSafetyManagement_getAccidentInfo(
      {
        id: this.dataFromDialog.accidentId
      }
    );
    console.log(formData);
    this.form = formData;
    this.hanldBidId([0, this.form.bidId]);
    this.form.accidentPersonList = formData.accidentPersonList.split(",");
    this.form.accidentPlateList = formData.accidentPlateList.split(",");

    this.accidentImageUrls = formData.accidentImageUrls
      ? formData.accidentImageUrls.split(",")
      : [];

    this.handleUrls = JSON.parse(formData.handleUrls);
  }
};
</script>

<style lang='sass' scoped>
.content-box 
  padding: 0

.form-label-base
  border-left: 2px solid $-color-primary-3
  padding-left: 8px
/deep/ .el-button--primary
  background-color: $-color-primary-3
  border-color: $-color-primary-3


/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  width: 130px;
</style>